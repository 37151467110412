<template>
    <div>
        <van-cell-group>
            
            <van-field placeholder="请填写车牌号码" label="车牌号码：" v-model="isData.number" />
            <van-field placeholder="请填写车辆类型" label="车辆类型：" v-model="isData.CarGenreText" />
            <van-field placeholder="请填写所有人" label="所有人：" v-model="isData.owner" />
            <van-field placeholder="请填写住址" label="住址：" v-model="isData.address" />
            <van-field placeholder="请填写使用性质" label="使用性质：" v-model="isData.natureText" />
            <van-field placeholder="请填写型号" label="型号：" v-model="isData.model" />
            <van-field placeholder="请填写品牌" label="品牌：" v-model="isData.brand" />
            <van-field placeholder="请填写车辆识别代码" label="车辆识别代码：" v-model="isData.identificationCode" />
            <van-field placeholder="请填写发动机号" label="发动机号：" v-model="isData.engineCode" />
            <van-field placeholder="请填写注册日期" label="注册日期：" v-model="isData.recordDate" />
            <van-field placeholder="请填写发证日期" label="发证日期：" v-model="isData.openingDate" />
        </van-cell-group>
        <van-cell-group>
        
            <van-field placeholder="请填写动力类别" label="动力类别：" v-model="versoData.typeOfFuel" />
            <van-field placeholder="请填写核定载客数" label="核定载客数：" v-model="versoData.numPeople" />
            <van-field placeholder="请填写整备质量" label="整备质量：" v-model="versoData.curbWeight" />
            <van-field placeholder="请填写外廓尺寸" label="外廓尺寸：" :value="`${isgabarite1(gabarite1,versoData.gabarite1)}  X  ${isgabarite2(gabarite2,versoData.gabarite2)} X  ${isgabarite3(gabarite3,versoData.gabarite3)}`" @focus="isPopup" />
        </van-cell-group>
         <div class="info" v-if="carData.type === 1">
      
             <van-cell-group>
                <van-field placeholder="请填写姓名" label="姓名：" v-model="carData.name" />
                <van-field placeholder="请填写身份证号码" label="身份证号码：" v-model="carData.idcard" />
               
            </van-cell-group>
        </div>
        <div class="info" v-if="carData.type === 2">
           
             <van-cell-group >
                <van-field placeholder="请填企业名称" label="企业名称：" v-model="carData.enterprise" />
                <van-field placeholder="请填社会信用代码" label="社会信用代码：" v-model="carData.credit_code" />
            </van-cell-group>
        </div>
         <van-form >
            <van-row gutter="20">
                   <van-col span="18">
                        <van-field label="过磅重量：" placeholder="请填写过磅重量" v-model="batch_average_weight"> </van-field>
                   </van-col>
                   <van-col span="6" class="list-select1" >
                        <select v-model="batch_average_weight_type">
                            <option :value ="1">Kg</option>
                            <option :value ="2">吨</option>
                        </select>
                   </van-col>
              </van-row>
              <van-row gutter="20">
                   <van-col span="18">
                        <van-field label="扣杂重量：" placeholder="请填写扣杂重量" v-model="suttle_batch_average_weight" ></van-field>
                   </van-col>
                   <van-col span="6" class="list-select1">
                        <select  v-model="suttle_batch_average_weight_type">
                            <option :value ="1">Kg</option>
                            <option :value ="2">吨</option>
                        </select>
                   </van-col>
              </van-row>
             <van-field label="自定义编码：" placeholder="请填写自定义编码" v-model="defined_num" />
             <van-field
                readonly
                clickable
                name="picker"
                v-model="purchaseName"
                label="采购类型："
                placeholder="点击选择采购类型"
                @click="showPicker = true"
                />
                <van-popup v-model="showPicker" position="bottom"  :close-on-click-overlay="false">
                <van-picker
                    show-toolbar
                    :columns="columns"
                    value-key="name"
                    @confirm="onConfirm(1,$event)"
                    @cancel="showPicker = false"
                />
                </van-popup>
                  
            <van-field
                v-if="purchaseType !== 1"
                readonly
                clickable
                name="picker"
                v-model="ChanneName"
                label="选择采购渠道："
                placeholder="选择采购渠道"
                @click="qudaoshow = true"
                >
                    <template #button>
                    <van-button size="small" @click.stop="addChangeHan" type="info">新增渠道</van-button>
                </template>
                </van-field>
                <van-popup v-model="qudaoshow" position="bottom"  :close-on-click-overlay="false">
                <van-picker
                    show-toolbar
                    :columns="ChanneList"
                    value-key="shopName"
                    @confirm="onConfirm(2,$event)"
                    @cancel="qudaoshow = false"
                />
                </van-popup>

                <!-- //业务员 -->
            <van-field
                readonly
                clickable
                name="picker"
                v-model="principalUserName"
                label="选择业务员："
                placeholder="选择业务员"
                @click="userShow = true"
                ></van-field>
            <van-popup v-model="userShow" position="bottom"  :close-on-click-overlay="false">
            <van-picker
                show-toolbar
                :columns="userListInfo"
                value-key="realName"
                @confirm="onConfirm(6,$event)"
                @cancel="userShow = false"
            />
            </van-popup>

                <van-field name="radio" label="是否监销">
                    <template #input>
                        <van-radio-group v-model="is_sale_of" direction="horizontal">
                        <van-radio name="1">是</van-radio>
                        <van-radio name="2">否</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>

                 <van-field
                    readonly
                    clickable
                    name="picker"
                    v-model="vehicleType"
                    label="自定义车辆类型："
                    placeholder="点击选择车辆类型"
                    @click="vehicleTypeShow = true"
                    />
                    <van-popup v-model="vehicleTypeShow" position="bottom"  :close-on-click-overlay="false">
                    <van-picker
                        show-toolbar
                        :columns="vehicleTypeColumns"
                        value-key="defined_type"
                        @confirm="onConfirm(3,$event)"
                        @cancel="vehicleTypeShow = false"
                    />
                </van-popup>
                <div v-if="!isWechat">
                    <van-field name="uploader" label="图片上传">
                        <template #input>
                            <van-uploader  v-model="imageFile" :after-read="uploadershang('restsImg')" :before-read="beforeRead" multiple  :before-delete="deleteimg2"></van-uploader>
                        </template>
                    </van-field>
                </div>

                <div class="upimg" v-else>
                    <p>图片上传</p>
                    <p>
                        <span class="imgbox" v-for="(item,index) in WXimg" :key="index">
                            <van-image class="vanImage" width="80"  height="80"  :src="item" @click="bigImg(item)" >
                                <van-icon class="iconX" name="close" @click="delImgHan('arrPhoto',index)" />
                            </van-image>
                        </span>
                        <span class="uploaderImg" @click="WeChooseImage(9)"> 
                            <van-icon class="iconz" name="plus" />
                        </span>
                    </p>
                </div>

                 <van-field
                    readonly
                    clickable
                    name="picker"
                    v-model="allocation_type"
                    label="拆解分配机制："
                    placeholder="请选择分配机制"
                    @click="typePicker = true"
                    />
                    <van-popup v-model="typePicker" position="bottom"  :close-on-click-overlay="false">
                    <van-picker
                        show-toolbar
                        :columns="allocation"
                        value-key="name"
                        @confirm="onConfirm(4,$event)"
                        @cancel="typePicker = false"
                    />
                    </van-popup>

                <van-field v-model="dismantle_allocation_remark" rows="2"  autosize label="拆解要求" type="textarea"  placeholder="请输入拆解要求"  show-word-limit />

                <div class="table" v-if="tableData.length!=0">
                        <table >
                            <tr>
                                <td>项目</td>
                                <td>单价</td>
                                <td>数量</td>
                                <td>增费</td>
                                <td>操作</td>
                            </tr>
                            <tr v-for="(i,index) in tableData" :key="i.id">
                                <td>{{i.name}}</td>
                                <td>{{i.price}}</td>
                                <td>{{i.num}}</td>
                                <td>{{i.num * i.price}}</td>
                                <td @click="deletes(index,1)">删除</td>
                            </tr>
                        </table>
                </div>
                <p class="sun" v-if="tableData.length!=0">
                    <span>质检增费合计：</span><span>{{Q}}</span>
                </p>
                <div  class="btn_zhij1">
                    <van-button  type="primary" size="large" @click="addProject">添加/修改质检增费项目</van-button>
                </div>
                <div class="table"  v-if="DetetData.length!=0">
                    <table >
                        <tr>
                            <td>项目</td>
                            <td>单价</td>
                            <td>数量</td>
                            <td>扣费</td>
                            <td>操作</td>
                        </tr>
                        <tr v-for="(i,index) in DetetData" :key="i.id">
                            <td>{{i.name}}</td>
                            <td>{{i.price}}</td>
                            <td>{{i.num}}</td>
                            <td>{{i.num * i.price}}</td>
                            <td @click="deletes(index,2)">删除</td>
                        </tr>
                    </table>
                </div>
                <p class="sun"  v-if="DetetData.length!=0">
                    <span>质检扣费合计：</span><span>{{QQ}}</span>
                </p>
                <div  class="btn_zhij1">
                    <van-button   type="primary" size="large" @click="addDetention">添加/修改质检扣费项目</van-button>
                </div>
        </van-form>

        <van-popup  :closeable="true"  v-model="show" class="popup" :close-on-click-overlay="false">
              <p style="text-align: center; ">修改长宽高</p>
              <hr>
              <van-field label="长：" v-model="gabarite1" />
              <van-field label="宽：" v-model="gabarite2" />
              <van-field label="高：" v-model="gabarite3" />
        </van-popup>
         <van-popup
                v-model="isAddShow"
                :closeable="false"
                :close-on-click-overlay="false"
                position="bottom"
                :style="{
                    height: '100%',
                }"
            >
                <AddChannel ref="Channels" @addditch="addditch"  />
        </van-popup>
        <van-popup v-model="ProjectShow" closeable class="popupProject" :close-on-click-overlay="false">
            <IncreaseTheProject ref="Increase" @addOroject="addOroject" />
        </van-popup>
        <van-popup v-model="FeeShow" closeable class="popupProject" :close-on-click-overlay="false">
            <feeDeduction ref="DetentRef" @FeeOroject="FeeOroject" />
        </van-popup>
    </div>
</template>

<script>
import AddChannel from '../Quality/components/AddChannel.vue'
import feeDeduction from '../Quality/components/feeDeduction.vue'
import IncreaseTheProject from '../Quality/components/IncreaseTheProject.vue'
import {Toast,Dialog} from 'vant'
import Bus from '@/utils/bus'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import {is_post} from '@/Http/api'
    export default {
        components: {
            AddChannel,feeDeduction,IncreaseTheProject
        },
        props:['info','VinInfo','carInfo','listDataInfo'],
        computed: {
            isgabarite1() {
                return (n,e)=>{
                    let a = 0
                    if(n){
                        a = n
                    }else if(e){
                        a=e
                    }
                    return a
                }
            },
            isgabarite2() {
                return (n,e)=>{
                    let a = 0
                    if(n){
                        a = n
                    }else if(e){
                        a=e
                    }
                    return a
                }
            },
            isgabarite3() {
                return (n,e)=>{
                    let a = 0
                    if(n){
                        a = n
                    }else if(e){
                        a=e
                    }
                    return a
                }
            },
            Q() {
                let num = 0
                if(this.tableData.length!=0){
                   this.tableData.forEach(i=>{
                       num += Number(i.num * i.price)
                   })
                }
                return num
            },
            QQ() {
                let num = 0
                if(this.DetetData.length!=0){
                   this.DetetData.forEach(i=>{
                       num += Number(i.num * i.price)
                   })
                }
                return num
            },
            
        },
 
 
        watch: {
            info: {
                immediate: true,
                deep: true,
                handler(newValue, oldValue) {
                    this.isData = newValue?newValue: this.isData
                }
            },
            VinInfo: {
                immediate: true,
                deep: true,
                handler(newValue, oldValue) {
                    this.versoData = newValue?newValue:this.versoData
                }
            },
            carInfo: {
                immediate: true,
                deep: true,
                handler(newValue, oldValue) {
                    this.carData = newValue?newValue:this.carData
                }
            },
            wximagelocalids:{
                handler(newValue, oldValue){
                     if(newValue.length > 0 ){
                    this.toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "上传中..."
                    });
                    this.wxUpload(newValue[0])
                }else{
                    this.toast.clear();
                }
                },
                deep: true,
            },
            listDataInfo:{
                immediate: true,
                deep: true,
                handler(newValue, oldValue) {
                   
                    if(newValue != undefined){

                        this.isData.number = newValue.carNumberPlate
                        this.isData.CarGenreText = newValue.carType_text
                        this.isData.owner = newValue.carOwner
                        this.isData.address = newValue.address
                        this.isData.natureText = newValue.useAttributeId_text
                        this.isData.model = newValue.vehicleModel
                        this.isData.brand = newValue.vehicleBrand
                        this.isData.identificationCode = newValue.vin
                        this.isData.engineCode = newValue.engineNumber
                        this.isData.recordDate = newValue.registrationTime
                        this.versoData.typeOfFuel = newValue.fuelTypeId_text
                        this.versoData.numPeople = newValue.approvedPassengersCapacity
                        this.versoData.curbWeight = newValue.curbWeight
                        this.gabarite1 = newValue.length
                        this.gabarite2 = newValue.width
                        this.gabarite3 = newValue.height
                        this.carData.type = newValue.type == 0 ?1:2
                        this.carData.name = newValue.type_value
                        this.carData.idcard = newValue.IDtype_id_value
                        this.carData.enterprise = newValue.type_value
                        this.carData.credit_code = newValue.IDtype_id_value
                        this.batch_average_weight =newValue.batch_average_weight
                        this.suttle_batch_average_weight = newValue.suttle_batch_average_weight
                        this.defined_num = newValue.defined_num
    
                        this.purchaseName = newValue.purchaseType == 1 ?'普通采购':'渠道采购'
                        this.purchaseType = newValue.purchaseType
    
                        this.ChanneName = newValue.PurchasingChannelsIdText
                        this.purchasingChannelsId = newValue.purchasingChannelsId
                        this.principalUserId = newValue.principalUserId
                        this.principalUserName = newValue.PrincipalUserIdText
                        this.is_sale_of =  `${newValue.is_sale_of}`
    
                        this.vehicleType = newValue.DefinedNumText
                        this.vehicleTypeId = newValue.defined_num_id 
                        this.dismantle_allocation_remark = newValue.dismantle_allocation_remark 
    
                        this.dismantle_allocation_type = newValue.dismantle_allocation_type 
                        if(newValue.dismantle_allocation_type == 1){
                            this.allocation_type = '预处理后自动拆解'
                        }else  if(newValue.dismantle_allocation_type == 2){
                            this.allocation_type = '预处理后资料合格手动拆解'
                        }else  if(newValue.dismantle_allocation_type == 3){
                            this.allocation_type = '预处理后资料合格自动拆解'
                        }
                        if(newValue.other_photos){
    
                            let arr =  newValue.other_photos.split(',')
                            let arr1 =  []
                            arr.forEach(i=>{
                                let obj = {
                                    url:i
                                }
                                arr1.push(obj)
                            })
                            this.imageFile = arr1
                            this.WXimg =arr
                        }
    
                        this.tableData = newValue.quality_price_detail
                        this.DetetData = newValue.quality_price_deduction_detail
                    }
                }
            }
        },
           
        
        data() {
            return {
                gabarite1:"",//轮毂
                gabarite2:"",//轮毂
                gabarite3:"",//轮毂
                isData:{
                    number:"",
                    CarGenreText:"",
                    owner:"",
                    address:"",
                    natureText:"",
                    model:"",
                    brand:"",
                    identificationCode:"",
                    engineCode:"",
                    recordDate:"",
                    openingDate:"",
                },//识别信息
                versoData:{
                    typeOfFuel:"",
                    numPeople:"",
                    curbWeight:"",
                    gabarite1:"",
                    gabarite2:"",
                    gabarite3:"",
                },//识别信息
                carData:{
                    type:1,
                    name:"",
                    idcard:"",
                    enterprise:"",
                    credit_code:"",
                },
                show:false,
                isAddShow:false,
                ProjectShow:false,
                FeeShow:false,
                columns: [
                    // '普通采购','渠道采购'
                    {id:1,name:'普通采购'},
                    {id:2,name:'渠道采购'},
                ],
                allocation:[
                    
                     {id:1,name:'预处理后自动拆解'},
                     {id:2,name:'预处理后资料合格手动拆解'},
                     {id:3,name:'预处理后资料合格自动拆解'},
                ],
                dismantle_allocation_type:2,//拆解机制
                allocation_type:'预处理后资料合格手动拆解',//拆解机制
                dismantle_allocation_remark:'',//拆解要求
                defined_num:'',//自定义编号
                purchasingChannelsId :'',//采购渠道
                purchaseType :1,//采购类型
                other_photos :'',//其他照片
                quality_inspection :'',//质检增费
                quality_inspection_deduction :'',//质检扣费
                quality_inspectionId :'',//质检增费
                quality_inspection_deductionId :'',//质检扣费
                batch_average_weight :'',//过磅重量
                suttle_batch_average_weight :'',// 扣杂重量
                quality_show:false,//增肥
                inspection_show:false,//扣费
                quality_List:[],//增肥数组
                inspection_List:[],//扣费数组
                batch_average_weight_type:1,
                suttle_batch_average_weight_type:1,
                 purchaseName:'普通采购',//采购选择汉子
                showPicker:false,//采购选择弹窗
                qudaoshow:false,//渠道弹窗
                isAddShow:false,//新增渠道
                ChanneList:[],//渠道列表
                ChanneName:'',//渠道列表
                WXimg:[],//其他照片
                vehicleTypeColumns: [],//自定义车辆类型
                typePicker:false,//拆解机制
                vehicleTypeShow:false,//自定义车辆类型
                vehicleType:'',//自定义车辆类型
                vehicleTypeId:'',//自定义车辆类型
                 is_sale_of:'2',//是否监销
                longClick:0,
                timeOutEvent:0,
                fileList2:[],
                VinUrl:"",
                vindata:"",
                tableData:[],//数据
                DetetData:[],//数据
                imageFile:[],//更多图片
                 wximagelocalids:[],//判断上传张数
                 toast:null,
                 userListInfo:[],//负责人
                userShow:false,
                principalUserId:'',
                principalUserName:'',
            }
        },
        mounted () {
             if(this.isWechat){
                this.wcConfig();
            }
            this.getPurchasing();
            this.getQuality();
            this.getMechanism()
            this.userList()
            Bus.$on('frontUrlInfo', (e) => {
                this.isData = e
            })
            Bus.$on('versoDataInfo', (e) => {
                this.versoData = e
            })
            Bus.$on('carDataInfo', (e) => {
                this.carData = e
            })
        },
       
        
        methods: {
            isPopup() {
                this.show=true
                this.gabarite1  = this.versoData.gabarite1 
                this.gabarite2  = this.versoData.gabarite2 
                this.gabarite3  = this.versoData.gabarite3 
            },
             //负责人
             userList(){
                this.$http.post('index.php/batch/userList').then(res=>{
                    console.log(res)
                    if(res.data.code == 0){
                        this.userListInfo = res.data.data
                    }
                })
            },
             //选择采购类型
            onConfirm(i,v){
              
                if( i==1 ){
                    this.purchaseName = v.name
                    this.purchaseType = v.id ;
                    if(this.purchaseType != 1) {
                        this.getPurchasing()
                    }else{
                        this.principalUserId = ''
                        this.principalUserName = ''
                        this.purchasingChannelsId = ''
                        this.ChanneName = ''
                    }
                    this.showPicker = false;
                }else if( i==2 ){
                    this.purchasingChannelsId = v.id
                    this.ChanneName = v.shopName
                
                    this.repetitionHan(v)
                    this.qudaoshow = false;
                }else if( i==3 ){
                    this.vehicleType = v.defined_type
                    this.vehicleTypeId = v.id
                    this.vehicleTypeShow = false
                }else if( i==4 ){
                    this.allocation_type = v.name
                    this.dismantle_allocation_type = v.id
                    this.typePicker = false
                }else if( i==6){
                    this.principalUserId=v.id
                    this.principalUserName=v.realName
                    this.userShow = false
                }
            },
             //查找相关负责人
             async repetitionHan(val){
                try{

                    let data = encipherMent( JSON.stringify({id:val.id}))
                    let param = new URLSearchParams();
                    param.append("value",data);
                    const res = await is_post('index.php/index/Purchasing_Channels/_get',param).then(res=>res)
                    if(res.data.realName){
                        if(this.principalUserId){
                            Dialog.confirm({
                            title:'提示' ,
                            message: `渠道中有关联业务员 ${res.data.realName} 是否更换？`,
                            theme: 'round-button',
                            }).then(() => {
                                this.principalUserId = res.data.salesmanId
                                this.principalUserName = res.data.realName
                            })
                            .catch(() => {});
                        }else{
                            this.principalUserId = res.data.salesmanId
                                this.principalUserName = res.data.realName
                        }
                    }
                }
                catch{}
            
            },
             //质检列表
            getQuality(){
                this.name = ''
                this.number = ''
                this.$http.post('index.php/quality_price/_list').then(res=>{
                    if(res.data.code == 0) {

                        this.isArr =res.data.data
                        let arr = []
                        res.data.data.forEach(i => {
                            let obj = {
                                test:i.name ,
                                price: i.price,
                                id:i.id
                            }
                            arr.push(obj)
                        });
                        this.quality_List = arr
                    }else if(res.data.code == -1) {
                           this.islogout()
                       }else{
                           this.$toast.fail(res.data.msg)
                       }
                })
                this.$http.post('index.php/quality_price_deduction/_list').then(res=>{
                    if(res.data.code == 0){
                        this.isArr =res.data.data
                        let arr = []
                        res.data.data.forEach(i => {
                            let obj = {
                                test:i.name,
                                price: i.price,
                                id:i.id
                            }
                            arr.push(obj)
                        });
                        this.inspection_List = arr
                    }else if(res.data.code == -1) {
                           this.islogout()
                       }else{
                           this.$toast.fail(res.data.msg)
                       }
                })
                this.$http.post('index.php/defined_num/_list').then(res=>{
                    if(res.data.code == 0){
                        console.log(res)
                        this.vehicleTypeColumns = res.data.data
                    }else if(res.data.code == -1) {
                           this.islogout()
                       }else{
                           this.$toast.fail(res.data.msg)
                       }
                })
            },
               //新增渠道
            addChangeHan(){
                this.isAddShow = true
                this.$nextTick(()=>{
                    this.$refs.Channels.getNmae()
                })
            },
            //获取采购渠道
             getPurchasing(){
                this.$http.post('index.php/index/Purchasing_Channels/purchasingChannelsList2').then(res=>{

                    this.ChanneList = res.data.data
                })
            },
             //新增渠道取消
            addditch(e){
                if(e){
                   this.purchasingChannelsId = e.id
                   this.ChanneName = e.shopName
                }
                this.isAddShow = false
            },
             deleteimg2(file,datail){
              this.imageFile.forEach((item,index)=>{
                   if(index == datail.index){   // 判断删除的下标是否正确
                        this.imageFile.splice(index,1) // 使用遍历找到的index去数组里面删除
                    }
              })
              this.WXimg.forEach((item,index)=>{
                if(index == datail.index){   // 判断删除的下标console.log(this.WXimg)
                    this.WXimg.splice(index,1) // 使用遍历找到的index去数组里面删除
                }
            })
           },
            beforeRead(file){
           
                if(file instanceof Array){
                    for(let i=0;i<file.length;i++){
                        if ((file[i].type !== 'image/jpeg') && (file[i].type !== 'image/png')) {
                            Toast('请上传 jpg/png 格式图片');
                            return false;
                        }
                        return true;
                    }
                
                }else{
                    if((file.type !== 'image/jpeg') && (file.type !== 'image/png')) {
                        Toast('请上传 jpg/png 格式图片');
                        return false;
                    }
                    return true;
                }
           },
           //删除
             deletes(i,num){
                if(num == 1 ){
                    this.tableData.splice(i,1)
                }else {
                    this.DetetData.splice(i,1)
                }
            },
            //质检增费
            addOroject(e){
                console.log(e)
                this.ProjectShow = false
                let o=false
                this.tableData.forEach(i=>{
                    if(i.id === e.id){
                        i.num = e.num
                        o = true
                    }
                })
                if(!o){
                    this.tableData.push(e)
                }
            },
            //质检扣费
            FeeOroject(e){
                 this.FeeShow = false
                 let o=false
                this.DetetData.forEach(i=>{
                    if(i.id === e.id){
                        i.num = e.num
                        o = true
                    }
                })
                if(!o){
                    this.DetetData.push(e)
                }
            },
            //质检扣费
            addDetention(){
                this.FeeShow = true
                 this.$nextTick(()=>{
                    this.$refs.DetentRef.getData()
                })
            },
            //质检增费
            addProject(){
                this.ProjectShow = true
                this.$nextTick(()=>{
                    this.$refs.Increase.getData()
                })
            },
            beforeRead(file){
           
                if(file instanceof Array){
                    for(let i=0;i<file.length;i++){
                        if ((file[i].type !== 'image/jpeg') && (file[i].type !== 'image/png')) {
                            Toast('请上传 jpg/png 格式图片');
                            return false;
                        }
                        return true;
                    }
                
                }else{
                    if((file.type !== 'image/jpeg') && (file.type !== 'image/png')) {
                        Toast('请上传 jpg/png 格式图片');
                        return false;
                    }
                    return true;
                }
           },
              // 上传照片
           uploadershang(i){
                return file =>{
                     file.status = "uploading";
                    file.message = "上传中...";
                    if(file instanceof Array){
                        file.forEach(item=>{
                           
                            this.upImages(i,item)
                         
                            console.log(i)
                        })
                    }else{
                        this.upImages(i,file)
                    }
                }
           },
           upImages(i,file){
                file.status = "uploading";
                file.message = "上传中...";
                let params = new FormData(); //创建form对象
                params.append("file", file.file);
                this.$http.post("/index.php/index/upload/index", params)
                .then((res) => {
                    // toast.clear()//清除加载效果
                if (res.data.code == 0) {
                    file.status = "done";
                       if(i=='restsImg'){
                            this.WXimg.push(res.data.data)
                        }
                    } else if(res.data.code == -1){
                        deleteCookie('token')
                        this.$toast.fail(res.data.msg)
                        this.$router.push('/')
                    }else{
                        file.status = "failed";
                        file.message = "上传失败";
                        this.$toast.fail(res.data.msg)
                    }
                }).catch(()=>{
                    if(i=='restsImg'){
                        this.imageFile = []
                    }
                    // toast.clear()//清除加载效果
                    file.status = "failed";
                    file.message = "上传失败";
                    this.$toast.fail('网络开小差了，请稍后重试！')
                })
           }
        },
    }
</script>

<style lang="scss" scoped>
  .popup{
    width: 80%;
    padding: 20px;
    .btn-ok{
        width: 100%;
        display: flex;
        .van-button{
            flex: 1;
        }
    }
}
.upimg{
    padding: 0 12px;
    .uploaderImg{
        display: inline-block;
        width: 100px ;
        height: 100px ;
        border: 1px solid #eee;
        position: relative;
        .iconz{
            position: absolute;
            top: 42%;
            left: 38%;

        }
    }
}
 .vanImage{
    position: relative;
    margin-right: 10px;
    margin-top: 10px;
        .iconX{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}
.table{
    padding:10px;
    margin-top: 10px;
    table,table tr th, table tr td { border:1px solid #000; }
    table { 
        width: 100%;
        text-align: center; 
        border-collapse: collapse; 
        
    }
}
.popupProject{
    width: 80%;
}
.btn_zhij1{
    padding: 0 20px;
    text-align: center;
     .van-button{
        margin-bottom: 5px;
    }
}

</style>