<template>
    <div>
         <div class="content">
            <van-grid :column-num="2">
                <van-grid-item>
                    <p>行驶证正面：</p>
                    <div v-if="!isWechat">
                        <van-uploader v-model="fileList1" :after-read="uploadershang(1)" :before-delete="deleteimg(1)"   preview-size="150px"  :max-count="1">
                            <img style="width:150px;height:150px;" src="../../assets/zhanwu.jpg" alt="">
                        </van-uploader>
                    </div>
                    <div v-else>
                        <img v-if="!frontUrl" style="width:150px;height:150px" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'front',9)">

                        <div v-else style="width:150px;height:150px">
                            <van-image class="vanImage" style="width:150px;height:150px"  :src="frontUrl" @click="bigImg(frontUrl)" >
                                <van-icon class="iconX" name="close" @click.stop="delImgHan('front')" />
                            </van-image>
                        </div>
                    </div>
                </van-grid-item>
                <van-grid-item>
                    <p>行驶证反面：</p>
                    <div v-if="!isWechat">
                        <van-uploader v-model="fileList2" :after-read="uploadershang(2)" :before-delete="deleteimg(2)"  preview-size="150px"  :max-count="1">
                            <img style="width:150px;height:150px" src="../../assets/zhanwu.jpg" alt="">
                        </van-uploader>
                    </div>
                    <div v-else>
                        <img v-if="!reverseUrl" style="width:150px;height:150px" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'reverse',9)">
                    
                        <div v-else style="width:150px;height:150px">
                            <van-image class="vanImage" style="width:150px;height:150px"  :src="reverseUrl" @click="bigImg(reverseUrl)" >
                                <van-icon class="iconX" name="close" @click.stop="delImgHan('reverse')" />
                            </van-image>
                        </div>
                    </div>
                </van-grid-item>
                <van-grid-item>
                    <p>车辆45°角方向：</p>
                    <div v-if="!isWechat">
                        <van-uploader v-model="fileList3" :after-read="uploadershang(3)" :before-delete="deleteimg(3)"  preview-size="150px" :max-count="1">
                            <img style="width:150px;height:150px" src="../../assets/zhanwu.jpg" alt="">
                        </van-uploader>
                    </div>
                    <div v-else>
                        <img v-if="!path" style="width:150px;height:150px" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'45Degrees',9)">
                
                        <div v-else style="width:150px;height:150px">
                            <van-image class="vanImage" style="width:150px;height:150px"  :src="path" @click="bigImg(path)" >
                                <van-icon class="iconX" name="close" @click.stop="delImgHan('45Degrees')" />
                            </van-image>
                        </div>
                    </div>
                </van-grid-item>
                <van-grid-item>
                    <p>Vin码照片：</p>
                    <div v-if="!isWechat">
                       <van-uploader v-model="fileList4" :after-read="uploadershang(4)" :before-delete="deleteimg(4)"  preview-size="150px" :max-count="1">
                            <img style="width:150px;height:150px" src="../../assets/zhanwu.jpg" alt="">
                        </van-uploader>
                    </div>
                    <div v-else>
                        <img v-if="!vin_photos" style="width:150px;height:150px" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'Vin',9)">
                        <div v-else style="width:150px;height:150px">
                            <van-image class="vanImage" style="width:150px;height:150px"  :src="vin_photos" @click="bigImg(vin_photos)" >
                                <van-icon class="iconX" name="close" @click.stop="delImgHan('Vin')" />
                            </van-image>
                        </div>
                    </div>
                </van-grid-item>
                <van-grid-item>
                   <p>车主信息：</p>
                    <div v-if="!isWechat">
                       <van-uploader v-model="fileList5" :after-read="uploadershang(5)" :before-delete="deleteimg(5)"  preview-size="150px" :max-count="1">
                            <img style="width:150px;height:150px" src="../../assets/zhanwu.jpg" alt="">
                        </van-uploader>
                    </div>
                    <div v-else>
                        <img v-if="!carInfo_photos" style="width:150px;height:150px" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'carInfo',9)">
                        <div v-else style="width:150px;height:150px">
                            <van-image class="vanImage" style="width:150px;height:150px"  :src="carInfo_photos" @click="bigImg(carInfo_photos)" >
                                <van-icon class="iconX" name="close" @click.stop="delImgHan('carInfo')" />
                            </van-image>
                        </div>
                    </div>
                </van-grid-item>
                <van-grid-item v-if="isData.type==1||imageUrlF">
                   <p>身份证反面：</p>
                    <div v-if="!isWechat">
                       <van-uploader v-model="fileList9" :after-read="uploadershang(9)" :before-delete="deleteimg(9)"  preview-size="150px" :max-count="1">
                            <img style="width:150px;height:150px" src="../../assets/zhanwu.jpg" alt="">
                        </van-uploader>
                    </div>
                    <div v-else>
                        <img v-if="!imageUrlF" style="width:150px;height:150px" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'Fan')">
                        <div v-else style="width:150px;height:150px">
                            <van-image class="vanImage" style="width:150px;height:150px"  :src="imageUrlF" @click="bigImg(imageUrlF)" >
                                <van-icon class="iconX" name="close" @click.stop="delImgHan('Fan')" />
                            </van-image>
                        </div>
                    </div>
                </van-grid-item>
                <van-grid-item>
                   <p>毁形之后的照片：</p>
                    <div v-if="!isWechat">
                       <van-uploader v-model="fileList6" :after-read="uploadershang(6)" :before-delete="deleteimg(6)"  preview-size="150px" :max-count="1">
                            <img style="width:150px;height:150px" src="../../assets/zhanwu.jpg" alt="">
                        </van-uploader>
                    </div>
                    <div v-else>
                        <img v-if="!destruction_photos" style="width:150px;height:150px" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'hui',9)">
                    
                        <div v-else style="width:150px;height:150px">
                            <van-image class="vanImage" style="width:150px;height:150px"  :src="destruction_photos" @click="bigImg(destruction_photos)" >
                                <van-icon class="iconX" name="close" @click.stop="delImgHan('hui')" />
                            </van-image>
                        </div>
                    </div>
                </van-grid-item>
                <van-grid-item>
                   <p>销户证明：</p>
                    <div v-if="!isWechat">
                        <van-uploader v-model="fileList7" :after-read="uploadershang(7)" :before-delete="deleteimg(7)"  preview-size="150px" :max-count="1">
                            <img style="width:150px;height:150px" src="../../assets/zhanwu.jpg" alt="">
                        </van-uploader>
                    </div>
                    <div v-else>
                        <img v-if="!cancellation_photos" style="width:150px;height:150px" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'xiaohu',9)">
                    
                        <div v-else style="width:150px;height:150px">
                            <van-image class="vanImage" style="width:150px;height:150px"  :src="cancellation_photos" @click="bigImg(cancellation_photos)" >
                                <van-icon class="iconX" name="close" @click="delImgHan('xiaohu')" />
                            </van-image>
                        </div>
                    </div>
                </van-grid-item>
                <van-grid-item>
                    <p>断梁照：</p>
                    <div v-if="!isWechat">
                        <van-uploader v-model="fileList8" :after-read="uploadershang(8)" :before-delete="deleteimg(8)" preview-size="150px" :max-count="1">
                            <img style="width:150px;height:150px" src="../../assets/zhanwu.jpg" alt="">
                        </van-uploader>
                    </div>
                    <div v-else>
                        <img v-if="!break_beam_photos" style="width:150px;height:150px" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'duan')">
                    
                        <div v-else style="width:150px;height:150px">
                            <van-image class="vanImage" style="width:150px;height:150px"  :src="break_beam_photos" @click="bigImg(break_beam_photos)" >
                                <van-icon class="iconX" name="close" @click="delImgHan('duan')" />
                            </van-image>
                        </div>

                    </div>
                </van-grid-item>
            </van-grid>
        </div>
    </div>
</template>

<script>
import {Toast} from 'vant'
    export default {
        props:['listDataInfo'],
        data() {
            return {
                fileList1: [],//照片
                fileList2: [],//照片
                fileList3: [],//照片
                fileList4: [],//照片
                fileList5: [],//照片
                fileList6: [],//照片
                fileList7: [],//照片
                fileList8: [],//照片
                fileList9: [],//照片
                wximagelocalids:[],//判断上传张数
                toast:null,//转圈圈
                frontUrl:"",//行驶证照片
                reverseUrl:"",//行驶证照片
                vin_photos:"",//Vin照片
                path:"",//车牌号
                destruction_photos:"",//毁坏型照片
                cancellation_photos:"",//销户照片
                break_beam_photos:"",//断粮照片
                carInfo_photos:"",//车主信息
                isData:{},//证明识别数据
                versoData:{},//反面数据
                imageUrlF:'',//反面数据
            }
        },
         mounted () {
            if(this.isWechat){

                this.wcConfig();
            }
        },
        watch: {
            wximagelocalids:{
                handler(newValue, oldValue){
                     if(newValue.length > 0 ){
                    this.toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "上传中..."
                    });
                    this.wxUpload(newValue[0])
                }else{
                    this.toast.clear();
                }
                },
                deep: true,
            },
            listDataInfo:{
                immediate: true,
                deep: true,
                handler(newValue, oldValue) {
                    // console.log(newValue)
                    if(newValue != undefined){

                        this.frontUrl = newValue.licensePlate
                        this.fileList1 = newValue.licensePlate? [{url:newValue.licensePlate}]:[]
                        this.reverseUrl = newValue.passSub_page
                        this.fileList2 =newValue.passSub_page? [{url:newValue.passSub_page}]:[]
                        let img = ''
                        if(newValue.vehicleImages){
                            img = newValue.vehicleImages.split(',')[0] 
                        } 
                        this.path =img
                        this.fileList3 = img?  [{url:img}] : []
                       
                        this.vin_photos = newValue.vin_photos
                        this.fileList4 = newValue.vin_photos?  [{url:newValue.vin_photos}] : []
                        this.carInfo_photos = newValue.identification_front
                        this.fileList5 = newValue.identification_front?  [{url:newValue.identification_front}] : []
                        this.imageUrlF = newValue.identification_back
                        this.fileList9 = newValue.identification_back?  [{url:newValue.identification_back}] : []
                        this.destruction_photos = newValue.destruction_photos
                        this.fileList6 = newValue.destruction_photos? [{url:newValue.destruction_photos}] : []
                        this.cancellation_photos = newValue.cancellation_photos
                        this.fileList7 = newValue.cancellation_photos?  [{url:newValue.cancellation_photos}] : []
                        this.break_beam_photos = newValue.break_beam_photos
                        this.fileList8 = newValue.break_beam_photos?  [{url:newValue.break_beam_photos}] : []
                    }

                }
            }
        },
       
         methods: {
            uploadershang(num) {
                return  (file)=>{
                    file.status = "uploading";
                    file.message = "上传中...";
                    let params = new FormData(); //创建form对象
                    params.append("file", file.file);
                    this.$http.post("/index.php/index/upload/index", params).then(async(res) => {
                         file.status = "done";
                         if(num == 1){
                            this.frontUrl = res.data.data
                            let url = 'index.php/index/Image_Recognition/vehicleLicenseFront'
                            const data =  await this.recognition(url,{path:res.data.data},1)
                        
                            this.$emit('shibieInfo',this.isData)
                       
                         }else if(num == 2){
                            this.reverseUrl = res.data.data
                            let url = 'index.php/index/Image_Recognition/vehicleLicenseBack'
                            const data = await this.recognition(url,{path:res.data.data},'verso')
                            this.$emit('shibieHou',this.versoData)
                         }else if(num == 3){
                             this.path = res.data.data
                         }else if(num == 4){
                             this.vin_photos = res.data.data
                         }else if(num == 5){
                             this.carInfo_photos = res.data.data
                            let url = 'index.php/vehicles_upload/idcardBusinessLicense'
                            const data = await this.recognition(url,{path:res.data.data})
                             this.$emit('carRen',this.isData)
                         }else if(num == 6){
                             this.destruction_photos = res.data.data
                         }else if(num == 7){
                             this.cancellation_photos = res.data.data
                         }else if(num == 8){
                             this.break_beam_photos = res.data.data
                         }else if(num == 9){
                             this.imageUrlF = res.data.data
                         }
                        //  file.status = "failed";
                     })
                }
            },
            deleteimg(num){
                return (file,detail)=>{
                    if(num == 1){
                    this.frontUrl = ''
                    this.fileList1 = []
                    return true
                }else if(num == 2){
                    this.reverseUrl = ''
                    this.fileList2 = []
                    return true
                }else if(num == 3){
                    this.path = ''
                    this.fileList3 = []
                    return true
                }else if(num == 4){
                    this.vin_photos = ''
                    this.fileList4 = []
                    return true
                }else if(num == 5){
                    this.carInfo_photos = ''
                    this.isData = {}
                    this.fileList5 = []
                    return true
                }else if(num == 6){
                    this.destruction_photos = ''
                    this.fileList6 = []
                    return true
                }else if(num == 7){
                    this.cancellation_photos = ''
                    this.fileList7 = []
                    return true
                }else if(num == 8){
                    this.break_beam_photos = ''
                    this.fileList8 = []
                    return true
                }else if(num == 9){
                    this.imageUrlF = ''
                    this.fileList9 = []
                    return true
                }
                }
            },
           
        },
    }
</script>

<style lang="scss" scoped>
.content{
    padding: 10px;
    margin-top: 50px;
   
    .content-up{
        padding: 10px !important;
    }
}
 .vanImage{
    position: relative;
    margin-right: 10px;
    margin-top: 10px;
        .iconX{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}
.upimg{
    padding: 0 12px;
    .uploaderImg{
        display: inline-block;
        width: 100px ;
        height: 100px ;
        border: 1px solid #eee;
        position: relative;
        .iconz{
            position: absolute;
            top: 42%;
            left: 38%;

        }
    }
}
</style>